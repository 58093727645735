@import '../../styles/var';

.mpk-menu{
  width: 100%;
  .subheader{
    cursor: pointer;
    position: sticky;
    top: 0;
    background: $mpk-background-color;
    z-index: 2;
  }
  .menus{
    li{
      list-style: none;
    }
    .rmd-icon{
      font-size: 20px;
      color: $mpk-font-color-D4;
    }
    .menu-item{
      border-top: thin solid rgba(0,0,0,.026);
      font-weight: 400;
      &.active{
        color: $mpk-primary-color;
        font-weight: 600;
        .rmd-icon{
          color: $mpk-primary-color;
        }
      }
      .rmd-list-item__text{
        margin: 0
      }
    }
  }
  .menu-group{
    border-bottom: thin solid $mpk-border-color-dark;
  }
  .menu-group-divider{
    color: $mpk-font-color-D3;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
}