@import "var";

html, body{
  background: $mpk-background-color
}

a{
  text-decoration: unset !important;
  cursor: pointer;
}

/*LAYOUT*/
.mpk-flex {
  display: flex;
  justify-content: flex-start;

  &.direction-row{
    flex-direction: row;
    &.reverse{
      flex-direction: row-reverse;
    }
  }

  &.direction-column {
    flex-direction: column;
    &.referse{
      flex-direction: column-reverse;
    }
    > .flex {
      min-height: 1px
    }
  }

  &.justify-center {
    justify-content: center;
    width: 100%
  }

  &.justify-arround {
    justify-content: space-around;
    width: 100%
  }

  &.justify-right, &.justify-end {
    justify-content: flex-end;
    width: 100%
  }

  &.justify-between {
    justify-content: space-between;
    width: 100%
  }

  &.align-center {
    align-items: center
  }

  &.align-start {
    align-items: flex-start
  }

  &.align-end {
    align-items: flex-end
  }

  &.wrap {
    flex-wrap: wrap
  }

  &.fill {
    width: 100%;
    height: 100%;
  }

  > .flex {
    flex: 1
  }

  > .flex-none {
    flex: none
  }

  > * {
    min-width: 0px;
    min-height: 0px;
  }

  .scrollable{
    overflow: auto;
  }
}

.mpk-full {
  &.full-width {
    width: 100%;
  }
  &.full-height {
    height: 100%;
  }
  &.viewport-width{
    width: 100vw;
  }
  &.viewport-height{
    height: 100vh;
  }
}

/*PADDING*/
$paddingSizes: (XS, $mpk-padding-XS),(S, $mpk-padding-S),(NS, $mpk-padding-NS),(N, $mpk-padding-N),(M, $mpk-padding-M),(L, $mpk-padding-L),(XL, $mpk-padding-XL),(NONE, 0);
@each $label, $size in $paddingSizes {
  .mpk-padding-#{$label}.padding-all{padding: #{$size}}
  .mpk-padding-#{$label}.padding-top{padding-top: #{$size}}
  .mpk-padding-#{$label}.padding-right{padding-right: #{$size}}
  .mpk-padding-#{$label}.padding-bottom{padding-bottom: #{$size}}
  .mpk-padding-#{$label}.padding-left{padding-left: #{$size}}
  .mpk-padding-#{$label}.all{padding: #{$size}}
  .mpk-padding-#{$label}.top{padding-top: #{$size}}
  .mpk-padding-#{$label}.right{padding-right: #{$size}}
  .mpk-padding-#{$label}.bottom{padding-bottom: #{$size}}
  .mpk-padding-#{$label}.left{padding-left: #{$size}}
}

$marginSizes: (XS, $mpk-margin-XS),(S, $mpk-margin-S),(NS, $mpk-margin-NS),(N, $mpk-margin-N),(M, $mpk-margin-M),(L, $mpk-margin-L),(XL, $mpk-margin-XL),(NONE, 0);
@each $label, $size in $marginSizes {
  .mpk-margin-#{$label}.margin-all{margin: #{$size}}
  .mpk-margin-#{$label}.margin-top{margin-top: #{$size}}
  .mpk-margin-#{$label}.margin-right{margin-right: #{$size}}
  .mpk-margin-#{$label}.margin-bottom{margin-bottom: #{$size}}
  .mpk-margin-#{$label}.margin-left{margin-left: #{$size}}
  .mpk-margin-#{$label}.all{margin: #{$size}}
  .mpk-margin-#{$label}.top{margin-top: #{$size}}
  .mpk-margin-#{$label}.right{margin-right: #{$size}}
  .mpk-margin-#{$label}.bottom{margin-bottom: #{$size}}
  .mpk-margin-#{$label}.left{margin-left: #{$size}}
}

$widths:(XS, 48px), (S, 72px), (N, 96px), (M, 144px), (L, 172px), (XL, 344);
@each $label, $size in $widths {
  .mpk-min-width-#{$label}{min-width: #{$size}}
  .mpk-max-width-#{$label}{
    max-width: #{$size};
    white-space: normal !important;
    line-height: normal !important;
  }
}

$fontSizes:(XS, $mpk-font-size-XS), (S, $mpk-font-size-S), (NS, $mpk-font-size-NS), (N, $mpk-font-size-N), (M, $mpk-font-size-M), (L, $mpk-font-size-L), (XL, $mpk-font-size-XL), (XXL, $mpk-font-size-XXL), (XXXL, $mpk-font-size-XXXL), (JUMBO, $mpk-font-size-JUMBO);
@each $label, $size in $fontSizes {
  .mpk-font.size-#{$label}{font-size: #{$size}}
}

$weights:(T, $mpk-font-weight-T), (L, $mpk-font-weight-L), (R, $mpk-font-weight-R), (M, $mpk-font-weight-M), (B, $mpk-font-weight-B), (XB, $mpk-font-weight-XB);
@each $label, $weight in $weights {
  .mpk-font.weight-#{$label}{font-weight: #{$weight}}
}

$colors:(D1, $mpk-font-color-D1), (D2, $mpk-font-color-D2), (D3, $mpk-font-color-D3), (L1, $mpk-font-color-L1), (L2, $mpk-font-color-L2), (L3, $mpk-font-color-L3), (P1, $mpk-primary-1), (P2, $mpk-primary-2);
@each $label, $color in $colors {
  .mpk-font.color-#{$label}{color: #{$color}}
}


$borderColors:(dark, $mpk-border-color-dark), (light, $mpk-border-color-light);
@each $label, $bcolor in $borderColors{
  .mpk-border.#{$label}.solid.border-top{border-top: thin solid #{$bcolor}}
  .mpk-border.#{$label}.solid.border-left{border-left: thin solid #{$bcolor}}
  .mpk-border.#{$label}.solid.border-right{border-right: thin solid #{$bcolor}}
  .mpk-border.#{$label}.solid.border-bottom{border-bottom: thin solid #{$bcolor}}
  .mpk-border.#{$label}.solid.border-all{border: thin solid #{$bcolor}}
  .mpk-border.#{$label}.dashed.border-top{border-top: thin dashed #{$bcolor}}
  .mpk-border.#{$label}.dashed.border-left{border-left: thin dashed #{$bcolor}}
  .mpk-border.#{$label}.dashed.border-right{border-right: thin dashed #{$bcolor}}
  .mpk-border.#{$label}.dashed.border-bottom{border-bottom: thin dashed #{$bcolor}}
  .mpk-border.#{$label}.dashed.border-all{border: thin dashed #{$bcolor}}
  .mpk-border.#{$label}.dotted.border-top{border-top: thin dotted #{$bcolor}}
  .mpk-border.#{$label}.dotted.border-left{border-left: thin dotted #{$bcolor}}
  .mpk-border.#{$label}.dotted.border-right{border-right: thin dotted #{$bcolor}}
  .mpk-border.#{$label}.dotted.border-bottom{border-bottom: thin dotted #{$bcolor}}
  .mpk-border.#{$label}.dotted.border-all{border: thin dotted #{$bcolor}}
}

$positions:(relative), (absolute), (fixed), (sticky);
@each $position in $positions {
  .mpk-position.position-#{$position}{position: #{$position}; top: 0;}
}

.mpk-margin-C{
  margin: 0 auto
}

.mpk-status{
  border-width: 2px;
  border-style: solid;
  padding: $mpk-padding-XS;
  letter-spacing: 1px;
  text-align: center;
  margin: $mpk-margin-XS;
  font-size: $mpk-font-size-S;
  font-weight: $mpk-font-weight-B;
  border-radius: 4px;
  &.clickable{
    box-shadow: 1px 1px 1px rgba(0,0,0,.25);
    cursor: pointer;
  }
}
$status: (progress, $mpk-color-progress), (error, $mpk-color-warn), (success, $mpk-color-success), (idle, $mpk-color-idle);
@each $label, $color in $status{
  .mpk-status.status-#{$label}{
    border-color: #{$color};
    color: #{$color};
    text-transform: uppercase;
  }
  .mpk-font.color-#{$label}{
    color: #{$color}
  }
}

$aligns: (left), (center), (right);
@each $align in $aligns{
  .mpk-align.align-#{$align}{
    text-align: #{$align}
  }
}

.mpk-link{
  color: $mpk-color-link;
  cursor: pointer;
}

.mpk-paper{
  border-radius: 4px;
  background: white
}

.mpk-initial-loader{
  height: auto;
}

.mpk-scrollable{
  overflow: auto;
}

/*ANIMATION*/
.mpk-animation{
  &.slide-in{
    animation: slide-in .5s ;
    animation-direction: alternate;
  }
  &.slide-in-down{
    animation: slide-in-down .2s ease-out;
    animation-direction: alternate;
  }
  &.slide-left{
    animation: slide-left .4s ease-out;
    animation-direction: alternate;
  }
  &.slide-right{
    animation: slide-right .4s ease-out;
    animation-direction: alternate;
  }
}

@keyframes slide-in{
  0%{
    transform: translateY(48px); 
    opacity: 0;
  };
  100%{
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-down{
  0%{
    transform: translateY(-48px); 
    opacity: 0;
  };
  100%{
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-left{
  0%{
    transform: translateX(-100%); 
    opacity: 0;
  };
  100%{
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-right{
  0%{
    transform: translateX(100%); 
    opacity: 0;
  };
  100%{
    transform: translateX(0);
    opacity: 1;
  }
}

@media screen and (max-height: 180px){
  .mpk-initial-loader{
    height: 100%;
  }
}

@media screen and (max-width: $mpk-media-sm) {
  .mpk-flex {
    &.direction-column-sm {
      flex-direction: column;

      &.child-fill > * {
        width: 100%;
      }

      &.reverse {
        flex-direction: column-reverse
      }
    }

    &.direction-row-sm {
      flex-direction: row;

      &.reverse {
        flex-direction: row-reverse
      }
    }

    &.justify-center-sm {
      justify-content: center;
    }

    &.justify-arround-sm {
      justify-content: space-around;
    }

    &.justify-right-sm {
      justify-content: flex-end;
    }

    &.justify-between-sm {
      justify-content: space-between;
    }
  }
}

// OVERIDE
.rmd-text-field-addon{
  height: auto !important
}

.rmd-table-cell--sticky-cell{
  border: none !important
}

.rmd-floating-label--active{
  background: white !important
}

.rmd-toggle-container{
  max-width: 100%
}

.rmd-table-cell--sticky{
  background: white !important
}

.rmd-table-cell--sticky-above{
  z-index: 5
}

.rmd-tab{
  max-width: unset !important;
}

.rmd-list-item__text{
  white-space: unset !important;
  margin-left: 8px;
}

// .rmd-floating-label{}